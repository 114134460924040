<template>
  <div class="wrap">
    <NavBar :title="$t('page.checkin')" left-arrow @click-left="$router.back()">
      <!-- <van-icon
        slot="left"
        name="arrow-left"
        color="#fff"
        @click="$router.back()"
      /> -->
    </NavBar>
    <div class="userinfo_bar">
      <VanImage width="70" height="70" :src="$img(userInfo.userimg)" round>
        <VanImage
          slot="error"
          width="70"
          height="70"
          :src="require('@/assets/Img/myImg/MyOne.png')"
          round
        />
      </VanImage>
      <div class="level_bar">
        <div class="nickname">
          {{
            userInfo.username ? userInfo.username : $t("newAdd.noLoginUserName")
          }}
        </div>
        <div class="hr"></div>
        <div class="level">{{ userInfo.rank_title }}</div>
      </div>

      <div class="calendar_bar">
        <CheckIn
          v-model="times"
          :checkin="checked"
          :today="today"
          @checkIn="checkIn"
          :disabledClick="true"
        />
      </div>
    </div>
    <div class="rule_bar" v-html="rule"></div>
    <div
      class="submit_bar"
      :class="{ btn_checked: todayIsChecked() }"
      @click="handleCommit"
    >
      {{ $t("checkin.submit") }}
    </div>
  </div>
</template>

<script>
import { getUserInfoNew } from "@/api";
import { CHECKWITHGIFTINIT, CHECKWITHGIFT } from "@/api/checkin";
import { getUserInfo } from "@/utils/tools";
import Tabbar from "@/components/Tabbar";
import CheckIn from "@/components/checkIn";
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  Grid,
  GridItem,
  tabs,
  tab,
  Button,
  Image as VanImage,
  Calendar,
} from "vant";
import { mapGetters } from "vuex";

export default {
  components: {
    NavBar,
    Calendar,
    [cell.name]: cell,
    [icon.name]: icon,
    [field.name]: field,
    [Toast.name]: Toast,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [tabs.name]: tabs,
    [tab.name]: tab,
    [Dialog.Component.name]: Dialog.Component,
    [Button.name]: Button,
    Tabbar,
    VanImage,
    CheckIn,
  },
  data() {
    return {
      // account: "",
      // userInfo: {},
      checked: [],
      rule: "",
      times: {},
      today: new Date(),
    };
  },
  watch: {
    times: {
      deep: true,
      handler({ year, month }) {
        if (year && month) {
          this.getChecked(year, month);
        }
      },
    },
  },
  created() {
    // getUserInfoNew();
  },
  computed: {
    ...mapGetters(["userInfo"]),
    account() {
      return (this.userInfo || {}).userphone;
    },
  },
  mounted() {
    // this.userInfo = getUserInfo();
    // this.account = getUserInfo().userphone;
  },
  methods: {
    todayIsChecked() {
      const hasNow = this.checked.filter(
        (item) =>
          this.$format(item, "yyyy-MM-dd") ===
          this.$format(new Date(this.today), "yyyy-MM-dd")
      );
      if (!!hasNow.length) {
        return true;
      }
      return false;
    },
    async handleCommit() {
      if (this.todayIsChecked()) return Toast(this.$t("todayChecked"));
      try {
        const res = await CHECKWITHGIFT();
        await this.getChecked(this.times.year, this.times.month);
        Toast(this.$t("checkin.success"));
      } catch (error) {
        console.error("签到失败:", error);
        if (error.msg) {
          return Toast(error.msg);
        }
        Toast(this.$t("checkin.fail"));
      }
    },
    checkIn() {},
    async getChecked(year, month) {
      const res = await CHECKWITHGIFTINIT(year, month);
      this.checked = res.lists || [];
      console.log(res);
      console.log(this.checked);
      this.rule = res.rule || "";
      if (
        res.current_day &&
        res.current_day !== this.$format(this.today, "yyyy/MM/dd")
      ) {
        this.today = new Date(res.current_day);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@Bc: var(--nav-background);
.wrap {
  min-height: 100vh;
  background: #f6f8f9;
  /deep/ [class*="van-hairline"]::after {
    border: none;
  }
  .userinfo_bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    // background-image: url("../../assets/recharge/bg.png");
    // background-color: var(--theme);
    background: @Bc;
    background-size: 100% 100%;
    @tradeinfo_height: calc(382rem / 16);
    @bottom: calc(-300rem / 16);
    padding: calc(24rem / 16) calc(18rem / 16) calc(55rem / 16);
    margin-bottom: calc(
      15rem / 16 + @tradeinfo_height - (@tradeinfo_height + @bottom)
    );
    .avatar {
      background-size: 100% 100%;
    }
    .level_bar {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #fff;
      margin-top: calc(14rem / 16);
      margin-bottom: calc(20rem / 16);
      // font-family: PingFangSC-Medium;
      font-size: calc(15rem / 16);
      .hr {
        width: 1px;
        height: calc(22rem / 16);
        background: #fff;
        margin: 0 calc(16rem / 16);
      }
    }
    .calendar_bar {
      position: absolute;
      background: #fff;
      border-radius: calc(10rem / 16);
      padding: calc(12rem / 16) calc(2rem / 16) calc(12rem / 16);
      bottom: @bottom;
      margin: 0 calc(13rem / 16);
    }
  }
  .form_bar {
    margin: 0 calc(13rem / 16);
    padding: calc(13rem / 16);
    border-radius: calc(10rem / 16);
    background: #0271dc;
    .submission_bar {
      @height: calc(60rem / 16);
      height: @height;
      background: #fff;
      border-radius: calc(5rem / 16);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 calc(10rem / 16);
      .title {
        font-size: calc(12rem / 16);
        // color: #6e87a0;
        // font-family: PingFangSC-Medium;
        white-space: nowrap;
      }
      > .input {
        margin-left: calc(10rem / 16);
        flex-grow: 1;
        overflow: hidden;
        border-bottom: 1px solid #0271dc;
        display: flex;
        // align-items: center;
        align-items: flex-end;
        > input {
          border: none;
          height: calc(@height * 0.5);
          color: #0271dc;
        }
      }
    }
    .type_bar {
      padding: calc(15rem / 16) 0;
      display: flex;
      justify-content: space-between;
      > div {
        line-height: calc(35rem / 16);
        text-align: center;
        color: #fff;
        // font-family: PingFangSC-Medium;
        font-size: calc(12rem / 16);
        font-weight: normal;
        white-space: nowrap;
        padding: 0 calc(12rem / 16);
        border-radius: calc(5rem / 16);
      }
      .active {
        background: #fff;
        color: #0271dc;
      }
    }
    .textarea_wrap {
      position: relative;
      padding: calc(13rem / 16);
      background: #fff;
      border-radius: calc(5rem / 16);
      .hash_bar {
        background: #fff;
        height: calc(125rem / 16);
        border: none;
        width: 100%;
      }
      .btn_past {
        border-radius: 50%;
        @size: calc(50rem / 16);
        width: @size;
        line-height: @size;
        text-align: center;
        // background-color: #5a00ff;
        background-color: var(--theme);
        color: #fff;
        position: absolute;
        bottom: calc(13rem / 16);
        right: calc(13rem / 16);
        &:active {
          opacity: 0.8;
        }
      }
    }
  }
  .rule_bar {
    background: #fff;
    margin: calc(13rem / 16);
    border-radius: calc(10rem / 16);
    padding: calc(12rem / 16) calc(8rem / 16) calc(20rem / 16);
  }
  .submit_bar {
    margin: calc(33rem / 16) calc(13rem / 16);
    text-align: center;
    // background: #0271dc;
    background: @Bc;
    @height: calc(45rem / 16);
    line-height: @height;
    color: #fff;
    border-radius: calc(@height / 2);
  }
  .btn_checked {
    color: var(--theme);
    background: rgb(206, 234, 255);
    font-weight: normal;
  }
}
</style>
